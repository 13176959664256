import { defineStore } from 'pinia'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { useNuxtApp } from '#app'
import { getAddresLocation } from '~/composible/getAddresLoc'

interface requestParamsInt {
  yandex_geo?: number
  google_geo?: number
  client_ip?: string
}
export const useCities = defineStore('cities', {
  state: () => ({
    currentCity: {},
    currentRegion: {},
    fullDataStreet: {},
    streetHouses: [],
    streetFiasForHouses: '',
    redirectObj: undefined as {} | undefined,
    streetPageLoading: false,
    softNoindex: null as boolean | null,
    requestParams: {} as requestParamsInt,
    needGetCity: false,
    defaultCity: {
      fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      short_name: 'г',
      name: 'Москва',
      url: 'msk',
      level: 'city',
      name_loc: 'Москве',
    },
    certainty: 'CERTAIN',
    allCities: null,
    allRegions: null,
    indexTopologies: [] as string[],
  }),
  getters: {
    getRedirectObj: state => state.redirectObj,
    getCity: state => state.currentCity,
    getRegion: state => state.currentRegion,
    getRequestParams: state => state.requestParams,
    getNeedGetCity: state => state.needGetCity,
    getDataStreet: state => state.fullDataStreet,
    getDefaultCity: state => state.defaultCity,
    getSoftNoindex: state => state.softNoindex,
    getStreetPageLoading: state => state.streetPageLoading,
    getStreetHouses: state => state.streetHouses,
    getStreetFiasForHouses: state => state.streetFiasForHouses,
    getCertainty: state => state.certainty,
    getAllCities: state => state.allCities,
    getAllRegions: state => state.allRegions,
    getIndexTopologies: state => state.indexTopologies,
  },
  actions: {
    clearStreet() {
      this.fullDataStreet = {} as any
    },
    async getAncestorsChainForAddress(fiasId: string) {
      const mainStore = useMainStore()
      const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
      try {
        const response = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${fiasId}/ancestors/`, {
          method: 'GET',
          headers: {
            ...getCommonHeaders.value,
          },
          params: {
            include_self: true,
            morph: true,
            site_id: import.meta.env.VITE_SITE_ID,
          },
        })

        return this.getAddressObjectsFromResponse(response)
      }
      catch (e: any) {
        throw this.createErrorFromResponse(e)
      }
    },

    getAddressObjectsFromResponse(response: any) {
      let street
      let city = this.findAddressObject(response, 'city')
      if (
        !city
        && response?.address_objects?.filter(el => el.level === 'street')
          ?.length > 1
      ) {
        city = response?.address_objects
          .slice()
          .reverse()
          .find(el => el.level === 'street')
        street = response?.address_objects.find(
          address => address.level === 'street',
        )
      }
      else if (!city) city = this.findAddressObject(response, 'street')
      else street = this.findAddressObject(response, 'street')

      this.currentCity = city
      this.indexTopologies = response.indexed_topologies

      return { city, street, res: response }
    },

    findAddressObject(response: any, level: string) {
      return response?.address_objects.find((el: any) => el.level === level)
    },

    createErrorFromResponse(error: any) {
      return createError({
        statusCode: error.response.status,
        fatal: true,
        message: error.message,
      })
    },

    async nuxtServerInit($route: any) {
      try {
        this.redirectObj = undefined
        const provider = process.env.PROVIDER_SLUG
        const providerId = process.env.PROVIDER_ID ? +process.env.PROVIDER_ID : undefined
        const tariffsStore = useTariffs()
        const mainStore = useMainStore()
        const $nuxt = useNuxtApp()
        const getBranch = import.meta.env.VITE_BRANCH

        if ($route.path[$route.path.length - 1] !== '/') {
          throw createError({
            statusCode: 404,
            fatal: true,
            message: 'not found',
          })
        }

        const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
        const cityFromCookie = useCookie('currentCity')
        const utmArrCookie = useCookie('utmArr')
        const addressFromCookie = useCookie('currentAddress')
        const abTestCookie = useCookie('ab_test')
        const sessionidCookie = useCookie('sessionid')
        const { 'x-real-ip': xRealIp } = useRequestHeaders()
        const reqHeaders = useRequestHeaders()
        const {
          'x-ab-test': abTest,
          host,
          'x-forwarded-host': newHost,
          'x-utm-state': xUtm,
          'if-modified-since': ifModifiedSince,
          'user-agent': userAgent,
          referer,
          cookie: ssrCookie,
        } = useRequestHeaders()
        const domain = newHost || (host === 'localhost:8080' ? process.env.MAIN_DOMAIN : host)
        mainStore.mainDomain = process.env.MAIN_DOMAIN
        mainStore.domain = domain
        mainStore.reqHeaders = reqHeaders
        const redirect = $nuxt.ssrContext?.event.res
        const partnerId = useCookie('partner_id')

        const numberPromise = async (val?: number) => {
          await mainStore.fetchPhoneNumber(val)
        }

        let respSiteOptions: any
        const respPromise = async () => {
          try {
            respSiteOptions = await $fetch('https://' + domain + '/api/site-options/', {
              method: 'GET',
              headers: {
                'authorization': 'Bearer ' + process.env.AUTH_TOKEN,
                'if-modified-since': ifModifiedSince,
                'X-Utm-State': xUtm,
                'user-agent': userAgent,
                referer,
              },
              params: {
                url: 'https://' + domain + $route.fullPath,
                branch: getBranch,
                site_version: abTest || abTestCookie.value || undefined,
              },
            })
          }
          catch (e: any) {
            console.log(e)
            return
            // Обработка ошибки
          }
        }

        mainStore.$patch({
          disabledPortal: $nuxt.$device.isDesktop,
          disabledMobile: $nuxt.$device.isDesktop,
        })

        const queries = new URLSearchParams($route.fullPath.split('?')[1])
        const utmArr = [] as any[]
        for (const query of queries.entries()) {
          if (
            query[0] === 'deviceuse__device'
            || query[0] === 'tv_box_count'
            || query[0] === 'service__service_type'
            || query[0] === 'sort'
          ) {
            this.redirectObj = {
              url: $route.path,
            }
            return
          }
          if (query[0] === 'yandex_geo')
            this.requestParams.yandex_geo = +query[1] || undefined
          if (query[0] === 'google_geo')
            this.requestParams.google_geo = +query[1] || undefined
          if (query[0] === 'partner_id') partnerId.value = query[1]
          if (query[0].includes('utm')) {
            utmArr.push(query)
          }
        }
        if (utmArr.length) {
          mainStore.$patch({
            utmArr,
          })
        }
        else if (utmArrCookie.value) {
          mainStore.$patch({
            utmArr: utmArrCookie.value,
          })
        }

        if (xRealIp) {
          this.requestParams.client_ip = xRealIp
          mainStore.$patch({
            clientIp: xRealIp,
          })
        }

        const diNotProviderCookie = useCookie('diNotProvider')
        const oldClientCookie = useCookie('oldClient')
        const providerClientCookie = useCookie('providerClient')
        const addressWOTariffsCookie = useCookie('addressWOTariffs')

        if ($route.name === 'quiz-complete') {
          this.redirectObj = {
            url: '/quiz/',
            code: 301,
          }
          return
        }
        else if (
          (provider && $route.path.includes('tariffs/')
            && $route.name !== 'city-tariffs-id')
            || (/\/providers\/[^/]*\/tariffs\//i.test($route.path)
              && $route.name !== 'city-providers-provider-tariffs-id'
            )
        ) {
          const url = $route.fullPath.replace(/\/tariffs/gi, '')
          this.redirectObj = {
            url: url,
            code: 301,
          }
        }
        else if (
          (/providers-provider.*/i.test($route.name)
            && $route.name !== 'city-providers-provider-tariffs-id'
            && !diNotProviderCookie.value)
        ) {
          mainStore.$patch({
            diProvider: true,
          })
        }

        const localRoute = ('/' + ($route.params.city === '' ? '' : $route.params.city?.filter(el => !!el).join('/') || '') + ($route.params.street ? '/' + $route.params.street : '')).replace(/^\/tariffs.*|^\/house-tariffs.*|^\/reviews.*|^\/for-house.*/, '/')

        let loc: any
        const { getAddresLoc } = getAddresLocation()
        const promiseLoc = async () => {
          loc = await getAddresLoc({
            url: $route.params['slug'] && $route.name === 'personal-address-slug'
              ? $route.params['slug']
                .filter((el: string) => !!el)
                .join('/')
              : $route.query.houseUrl
                ? $route.query.houseUrl
                : !$route.params['slug'] ? addressFromCookie.value || undefined : undefined,
            houseUrl: $route.params['slug'] && $route.name === 'city-address-slug'
              ? $route.params['city'].join('/') + '/' + $route.params['slug'].replace(/-dom-/g, '/')
              : undefined,
          })
        }
        await Promise.all([promiseLoc(), respPromise(), numberPromise(providerId || 0)])

        if (respSiteOptions?.last_modified) {
          redirect?.setHeader(
            'Last-Modified',
            new Date(respSiteOptions?.last_modified).toUTCString(),
          )
        }
        if (respSiteOptions?.not_modified) {
          this.redirectObj = {
            url: $route.fullPath,
            code: 304,
          }
          return
        }

        if (sessionidCookie.value) {
          mainStore.$patch({
            commonHeaders: {
              'ip': xRealIp || null,
              'X-Sessionid': sessionidCookie.value,
              'X-Forwarded-Host': host,
              'X-Utm-State': xUtm,
              'cookie': ssrCookie,
            },
          })
        }
        else {
          mainStore.$patch({
            commonHeaders: {
              'ip': xRealIp || null,
              'X-Forwarded-Host': host,
              'X-Utm-State': xUtm,
              'cookie': ssrCookie,
            },
          })
        }

        const config = respSiteOptions?.options.config as any

        mainStore.$patch({
          oldClient: !!oldClientCookie.value || false,
          providerClient: providerClientCookie.value || undefined,
          addressWOTariffs: !!addressWOTariffsCookie.value || false,
          configShowMap: config?.show_map,
          automation: config?.automation,
          quizBtnShow: config?.quiz_btn_show,
          btn60Show: config?.btn_60sec || config?.btn60sec || config?.btn60sec_test,
          loaderText: config?.loader_text,
          loaderTime: config?.loader_time,
          segmentation: config?.segmentation || false,
          woFilter: config?.address_wo_filter,
          saWStreets: !!config?.sa_w_streets,
          domainConfig: provider ? respSiteOptions?.options : undefined,
          metrikaId: respSiteOptions?.options?.yandex_id,
          uaId: respSiteOptions?.options?.google_id,
          providerId: providerId ? providerId + '' : undefined,
          woConsult: config?.wo_consult,
          hideNumber: config?.hide_phone_number,
          automatizationDirect: config?.automatization_direct,
          reverseAutomatizationTabs: config?.reverse_automatization_tabs,
          showGreenBtn: config?.show_green_btn,
          popularSortVersion: config?.popular_sort_version,
          test20: config?.test_20,
          tariffAbout: config?.tariff_about,
        })

        if (loc?.houseId || loc?.houseFiasId || (loc?.streetFiasId && loc?.houseNumber)) {
          const { city, street, res }
            = await this.getAncestorsChainForAddress(
              loc.fiasPath[loc.fiasPath.length - 1],
            )
          if (res?.redirect_status_code) {
            const oldUrl = $route.fullPath
            this.redirectObj = {
              url: oldUrl.replace(
                localRoute.slice(1),
                res.house?.url || res.address_objects[0].url,
              ),
              code: res.redirect_status_code,
            }
          }

          if ((tariffsStore.house?.fiasPath && !tariffsStore.house?.fiasPath?.includes(city.fias_id)) || (localRoute?.length > 2 && !localRoute.includes(city.url))) {
            tariffsStore.house = {}
            tariffsStore.passThroughProviderId = null
            const responce = await $fetch(`https://${domain}/api/locations/address-objects/chain-by-geo/`, {
              method: 'GET',
              headers: {
                ...getCommonHeaders.value,
              },
              params: {
                with_available_smart_filters: false,
                morph: true,
                url_geo: localRoute.replace(/^\/undefined/, ''),
                site_id: import.meta.env.VITE_SITE_ID,
                ...this.requestParams,
              },
            })

            const { city, street, res } = this.getAddressObjectsFromResponse(responce)
            this.$patch({
              currentCity: city,
              fullDataStreet: street,
            })

            tariffsStore.$patch({
              addressAncestors: res,
            })

            return
          }

          this.$patch({
            currentCity: city,
            fullDataStreet: street,
          })

          tariffsStore.$patch({
            addressAncestors: res,
          })

          if (!provider && /^\/house-tariffs\//i.test($route.path)) {
            this.redirectObj = {
              url: `/personal/address/${loc.autoGeneratedUrl}/`,
              code: 302,
            }
          }

          return
        }
        else if (localRoute?.length > 1) {
          let responce
          let responceCheck
          try {
            responceCheck = await $fetch(`https://${domain}/api/locations/address-objects/chain-by-geo/`, {
              method: 'GET',
              headers: {
                ...getCommonHeaders.value,
              },
              params: {
                with_available_smart_filters: false,
                morph: true,
                url_geo: localRoute.replace(/^\/undefined/, ''),
                site_id: import.meta.env.VITE_SITE_ID,
                ...this.requestParams,
              },
            })
          }
          catch (e) {
            console.log(e)
          }

          if (
            responceCheck.address_objects.length === 1
            && responceCheck.address_objects[0].level === 'region'
          ) {
            this.currentRegion = responceCheck.address_objects[0]
            if (tariffsStore.house?.fiasPath && !tariffsStore.house?.fiasPath?.includes(city.fias_id)) {
              tariffsStore.house = {}
              tariffsStore.passThroughProviderId = null
            }
            if (
              (cityFromCookie.value
                && typeof cityFromCookie.value === 'string')
            ) {
              const { city, res } = await this.getAncestorsChainForAddress(
                cityFromCookie.value,
              )
              tariffsStore.$patch({
                addressAncestors: res,
              })
              this.$patch({
                currentCity: city,
              })
            }
            return
          }
          else {
            responce = responceCheck
          }
          if (responce?.redirect_status_code) {
            const oldUrl = $route.fullPath
            this.redirectObj = {
              url: oldUrl.replace(
                localRoute.slice(1),
                responce.house?.url || responce.address_objects[0].url,
              ),
              code: responce?.redirect_status_code,
            }
          }
          const { city, street, res } = this.getAddressObjectsFromResponse(responce)

          if (responce.house) {
            throw createError({
              statusCode: 410,
              fatal: true,
              message: 'not found',
            })
          }

          tariffsStore.$patch({
            addressAncestors: res,
          })
          this.$patch({
            currentCity: city,
            fullDataStreet: street,
            certainty: res?.certainty,
          })
          if (tariffsStore.house?.fiasPath && !tariffsStore.house?.fiasPath?.includes(city.fias_id)) {
            tariffsStore.house = {}
            tariffsStore.passThroughProviderId = null
          }

          return
        }
        else if (
          (cityFromCookie.value
            && typeof cityFromCookie.value === 'string')
        ) {
          const { city, res } = await this.getAncestorsChainForAddress(
            cityFromCookie.value,
          )
          tariffsStore.$patch({
            addressAncestors: res,
          })
          this.$patch({
            currentCity: city,
          })
          if (/^\/tariffs.*|^\/house-tariffs.*|^\/reviews.*|^\/for-house.*/.test($route.path)) {
            let url = `/${city!.url}${$route.fullPath.replace(
              '/house-tariffs/',
              '/tariffs/',
            )}`
            if (provider) {
              url = url.replace(/\/tariffs/gi, '')
            }
            this.redirectObj = {
              url,
              code: 302,
            }
          }
          return
        }
        else if (this.requestParams.yandex_geo || this.requestParams.google_geo || /^\/tariffs.*|^\/house-tariffs.*|^\/streets.*|^\/reviews.*|^\/for-house.*/.test($route.path)) {
          const responce = await $fetch(`https://${domain}/api/locations/address-objects/chain-by-geo/`, {
            method: 'GET',
            headers: {
              ...getCommonHeaders.value,
            },
            params: {
              with_available_smart_filters: false,
              morph: true,
              provider: providerId || $route.params.provider,
              site_id: import.meta.env.VITE_SITE_ID,
              ...this.requestParams,
            },
          })

          const { city } = this.getAddressObjectsFromResponse(responce)
          let url = `/${city!.url}${$route.fullPath.replace(
            '/house-tariffs/',
            '/tariffs/',
          )}`
          if (provider) {
            url = url.replace(/\/tariffs/gi, '')
          }
          this.redirectObj = {
            url,
            code: 302,
          }

          return
        }
        else {
          this.needGetCity = true
          return
        }
      }
      catch (e: any) {
        console.log('catch this')
        console.log(e)
        throw createError({
          statusCode: e.statusCode === 410 ? 410 : 404,
          fatal: true,
          message: 'not found',
        })
      }
    },
  },
})
